<template>
  <div>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Important Custom Components
import { vueTopprogress } from 'vue-top-progress';

export default {
  components: {
    vueTopprogress,
  },

  computed: {
    ...mapGetters(['topPageLoader']),
  },

  watch: {
    topPageLoader(oldValue, newValue) {
      let status = newValue;

      if (status) {
        this.startLoader();
      } else {
        this.endLoader();
      }
    },
  },

  methods: {
    startLoader() {
      this.$refs.topProgress.done();
    },

    endLoader() {
      this.$refs.topProgress.start();
    },
  },
};
</script>
