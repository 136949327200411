import axios from 'axios';
import store from '../store';

const domainAPI = store.state.domainAPI;

// Login
const globalService_Login = (data) =>
  axios.post(domainAPI + '/api/auth/login', {
    email: data.email,
    password: data.password,
  });

// Google Login
const globalService_GoogleLogin = (data) =>
  axios.post(domainAPI + '/api/auth/login-via-google', {
    at: data.at,
  });

// Register User
const globalService_Register = (data) =>
  axios.post(domainAPI + '/api/user', {
    email: data.email,
    password: data.password,
    password_confirmation: data.password_confirmation,
    first_name: data.first_name,
    last_name: data.last_name,
    is_from_google: data.is_from_google,
    advertisement: data.advertisement,
    path: data.path,
  });

// Register Profile
const globalService_RegisterProfile = (data) =>
  axios.post(domainAPI + '/api/user-profile', {
    user_id: data.id,
    account_type: data.account_type,
    first_name: data.first_name,
    last_name: data.last_name,
    company_name: data.company_name,
    gender: data.gender,
    birth_date: data.birth_date,
    prefectures: data.prefectures,
    is_invoice_payment: data.is_invoice_payment
  });

// Get Current User Info
const globalService_GetCurrentUserInfo = (data) =>
  axios.get(domainAPI + '/api/user/show/my-details', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Email password reset link
const globalService_EmailPasswordResetLink = (data) =>
  axios.post(domainAPI + '/api/user/forgot-password', {
    email: data.email,
  });

// Email password reset link token checker
const globalService_EmailPasswordResetLinkTokenChecker = (data) =>
  axios.post(domainAPI + '/api/user/reset-password/' + data.token, {
    is_to_check: true,
  });

// Email password reset link change password
const globalService_EmailPasswordResetLinkUpdatePassword = (data) =>
  axios.post(domainAPI + '/api/user/reset-password/' + data.token, {
    is_to_check: false,
    password: data.password,
    password_confirmation: data.password_confirmation,
  });

// Get All Prefectures
const globalService_GetAllPrefectures = () =>
  axios.get(domainAPI + '/api/reference/prefectures');

// Create Inquiry
const globalService_CreateInquiry = (data) =>
  axios.post(domainAPI + '/api/inquiry', {
    user_id: data.id,
    company_name: data.company_name,
    name: data.name,
    email_address: data.email_address,
    inquiries: data.inquiries,
    message: data.message,
  });

// Download Image
const globalService_DownloadIMG = (data) =>
  axios.get(domainAPI + `/api/download-img?path=${data.path}&is_mix=${data.isMix}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
      contentType: 'image/png',
      accept: 'image/png',
    },
    responseType: 'blob',
  });

// Get Design List
const globalService_GetDesignList = () =>
  axios.get(domainAPI + '/api/request-item/list');

// Get Design Detail
const globalService_GetDesignDetail = (data) =>
  axios.get(domainAPI + `/api/request-item/${data.id}/detail`);

// Get House Layout
const globalService_GetHouseLayout = () =>
  axios.get(domainAPI + '/api/reference/house-layout');

export {
  globalService_Login,
  globalService_GoogleLogin,
  globalService_Register,
  globalService_RegisterProfile,
  globalService_GetCurrentUserInfo,
  globalService_EmailPasswordResetLink,
  globalService_EmailPasswordResetLinkTokenChecker,
  globalService_EmailPasswordResetLinkUpdatePassword,
  globalService_GetAllPrefectures,
  globalService_CreateInquiry,
  globalService_DownloadIMG,
  globalService_GetDesignList,
  globalService_GetDesignDetail,
  globalService_GetHouseLayout,
};
