// Import Vuex/Store
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    // User Data Related
    currentUser: {},
    staging: {
      accessToken: null,
      info: null,
      items: [],
      loader: true,
      uploadAlertModal: true,
    },
    isLoggedIn: false,

    // Component Data Related
    topPageLoader: null,
    modalLoader: false,
    modalLoaderMessage: null,
    currentPaginationPage: 1,

    // App Data Related
    currentRoute: null,
    // Backend APIs:
    domainAPI: process.env.VUE_APP_API,
  },

  mutations: {
    // User Data Related
    setUserData(state, data) {
      state.currentUser = data;
      state.isLoggedIn = true;
    },

    setStagingData(state, data) {
      state.staging = data;
    },

    setLogout(state) {
      localStorage.removeItem('_accessToken');
      localStorage.removeItem('_stagingRequestID');

      state.currentUser = {};
      state.isLoggedIn = false;
    },
  },

  actions: {
    // User Data Related
    setUserData: async (state, data) => state.commit('setUserData', data),
    setStagingData: async (state, data) => state.commit('setStagingData', data),

    // Component Data Related
    setLogout: (state) => state.commit('setLogout'),
  },

  getters: {
    getState: (state) => state,

    // User Data Related
    currentUser: (state) => state.currentUser,
    staging: (state) => state.staging,
    isLoggedIn: (state) => state.isLoggedIn,

    // Component Data Related
    topPageLoader: (state) => state.topPageLoader,
    modalLoader: (state) => state.modalLoader,
    modalLoaderMessage: (state) => state.modalLoaderMessage,

    // App Data Related
    currentRoute: (state) => state.currentRoute,
    domainAPI: (state) => state.domainAPI,
  },
});
