import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag'

// jquery
import jQuery from 'jquery'
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

Vue.use(VueGtag, {
  config: {id: 'G-XH37KHP40T'}
});

// Import Vue Clipboard2
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

// Import Vue Toasted
import Toasted from 'vue-toasted';

const options = {
  position: 'top-right',
  duration: 1000,
  className: ['bg-primary', 'text-white'],
  theme: 'toasted-primary'
}
Vue.use(Toasted, options);

// Use Axios Globally
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Import Vue Top Progress
import vueTopprogress from 'vue-top-progress';

Vue.use(vueTopprogress);

// Import Vue Meta
import VueMeta from 'vue-meta';
import 'aframe';

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

// Import Fontawesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
library.add(fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Import Bootstrap Vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

//Import Vue Wave
import Waves from 'vue-waves-effect';
import 'vue-waves-effect/dist/vueWavesEffect.css';

Vue.use(Waves);

import pagination from 'laravel-vue-pagination';

Vue.component('pagination', pagination);

// Import Google Auth 2
import GAuth from 'vue-google-oauth2';
const gauthOption = {
  clientId:
    '104381980755-jg5ldutuhc774epams7onu5lr97hsf7v.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: false,
};

Vue.use(GAuth, gauthOption);

// Custom Global CSS
import './main.css';

// BeerSlider CSS
import './assets/css/BeerSlider.css';

// Shooting Agency CSS
import './assets/css/style.css';

// ======================= VUE App Mount Configuration App ======================= //
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
