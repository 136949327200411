<template>
  <div>
    <top-page-progress></top-page-progress>

    <router-view></router-view>

    <modal-loader></modal-loader>
  </div>
</template>

<script>
// Import Custom Components
import TopPageProgress from './components/Top-Page-Progress';
import modalLoader from './components/Loader-Modal.vue';

export default {
  components: {
    'modal-loader': modalLoader,
    'top-page-progress': TopPageProgress,
  },
};
</script>
