<template>
  <b-modal
    id="modal-loader"
    ref="modalLoader"
    size="sm"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <div
      class="d-flex align-items-center justify-content-center"
      style="height: 50px"
    >
      <b-spinner style="color: #039be5"></b-spinner>
      <h5 class="m-0 ml-4">{{ modalLoaderMessage }} ...</h5>
    </div>
  </b-modal>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      message: this.$store.state.modalLoaderMessage,
    };
  },

  computed: {
    ...mapGetters(['modalLoaderMessage']),
  },
};
</script>
